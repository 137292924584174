// @ts-check

import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

// state
import { useAppDispatch, useAppSelector } from 'core/store';
import { getCampaignAsync, getFiltersAsync } from 'core/store/slices';

const useStateFilters = () => {
  const dispatch = useAppDispatch();
  const { filters } = useAppSelector((state) => state.queryBuilder);

  useEffect(() => {
    if (filters?.length > 0) return;

    dispatch(getFiltersAsync());
  }, [filters, dispatch]);
};

const useStateCampaign = () => {
  const dispatch = useAppDispatch();
  const reloadData = useAppSelector((state) => state.campaignData.reloadData);

  /** @type {{ id: string }} */
  let { id: campaignId } = useParams();
  useEffect(() => {
    if (!campaignId || !reloadData) {
      return;
    }

    dispatch(
      getCampaignAsync({
        campaignId,
      }),
    );
  }, [campaignId, dispatch, reloadData]);
};

export { useStateCampaign, useStateFilters };
