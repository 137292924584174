// @ts-check
import React from 'react';
import { Link } from 'react-router-dom';

const Admin = () => {
  return (
    <div className="container">
      <div className="row">
        <h2>
          <span className="highlight-gold" data-testid="support">
            Admin
          </span>
        </h2>
      </div>

      <div className="row">
        <div className="col col-lg-4">
          <div className="card">
            <div className="card-header" />
            <div className="card-body">
              <div className="card-event-icons">
                <div>
                  <i className="fa-solid fa-calendar-week" />
                </div>
                <div>
                  <Link to="/announcement/view/9cd09969-40d5-478a-848d-f39aded6199b">
                    Manage Announcements
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col col-lg-4">
          <div className="card">
            <div className="card-header" />
            <div className="card-body">
              <div className="card-event-icons">
                <div>
                  <i className="fa-solid fa-user-group" />
                </div>
                <div>Group Management</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Admin };
