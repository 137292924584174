// @ts-check
import React from 'react';
import styled from 'styled-components';
import { ElementTooltip, formatDate, SpinnerLoader } from 'core';

const TableContainer = styled.div`
  overflow-x: unset;
`;

const TwoItemContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

const StatusBadge = styled.span.attrs((props) => ({
  status: 'saved',
  className: `badge badge-${
    props.status === 'saved' ? 'info' : 'success'
  } text-dark p-1`,
}))`
  border-radius: 5px;
  width: 100%;
`;

const StyledLink = styled.a.attrs((props) => ({
  disabled: props.disabled || false,
}))`
  cursor: pointer;

  ${(props) =>
    props.disabled
      ? `
        --disabled-color: #6c757d;
        color: var(--disabled-color);
        cursor: not-allowed;
        text-decoration: none;
        &:hover {
          color: var(--disabled-color);
        }
      `
      : ''}
`;

/**
 * @param {{
 *  loading: boolean,
 *  campaign: CampaignModel,
 *  exportInProgress: boolean,
 *  isPreviewDataLoading: boolean,
 *  totalRecordCount: number,
 *  onPublishCampaign: () => void,
 *  onExportList: () => void,
 *  onExportAuditFile: () => void,
 *  onListSharedToggle: (checked: boolean) => void,
 *  onLogicSharedToggle: (checked: boolean) => void,
 *  onDetailClick: () => void,
 * }} props
 */
function DetailList({
  loading,
  campaign,
  exportInProgress,
  isPreviewDataLoading,
  totalRecordCount,
  onPublishCampaign,
  onExportList,
  onExportAuditFile,
  onListSharedToggle,
  onLogicSharedToggle,
  onDetailClick,
}) {
  const status = campaign.published ? 'Published' : 'Saved';

  const isHidden = campaign.details.sources.length === 0;

  // If upload file exists and Export File Summary: Total Export File Records is not "n/a" then
  // Records column should equal Total Export File Records.
  // If no upload file then Records column should equal the
  // preview table "Total rows in Campaign list" value.
  // Else there was likely an upload file but no matches, set Records column to zero (0).
  const totalRecords =
    campaign.fileName?.valueOf() !== undefined &&
    campaign.calculatedMatchSummary?.totalRecords > 0
      ? campaign.calculatedMatchSummary?.totalRecords
      : campaign.fileName?.valueOf() === undefined
      ? totalRecordCount
      : 0;

  return (
    <div>
      <TableContainer className="uds-table mb-2" tabIndex={0}>
        <table data-testid="details-table">
          <thead>
            <tr>
              <th scope="col">File upload(s)</th>
              <th scope="col">Date Created</th>
              <th scope="col">Created By</th>
              <th scope="col">Records</th>
              <th scope="col">Status</th>
              <th scope="col">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {campaign.fileName ? (
                  <TwoItemContainer>
                    <span>{campaign.fileName}</span>
                    {/* TODO: add class `text-maroon` when download WEB API is ready */}
                    <i className="fas fa-download" />
                  </TwoItemContainer>
                ) : (
                  ' '
                )}
              </td>
              <td>{formatDate(campaign.createdAt)}</td>
              <td>{campaign.createdByName}</td>
              <td>
                {!isPreviewDataLoading ? (
                  totalRecords
                ) : (
                  <SpinnerLoader
                    spinnerType="circle"
                    width="20px"
                    height="20px"
                  />
                )}
              </td>
              <td>
                <StatusBadge status={status.toLowerCase()}>
                  {status}
                </StatusBadge>
              </td>
              <td>
                <ElementTooltip
                  tooltipId="open-detail-tooltip"
                  tooltip={
                    'If the Campaign has not yet been Published, ' +
                    'all fields are editable by those with appropriate security.'
                  }
                >
                  <StyledLink
                    disabled={loading || campaign.published}
                    aria-describedby="open-detail-tooltip"
                    onClick={onDetailClick}
                  >
                    <i className="fas fa-edit mr-2" />
                    Edit Campaign
                  </StyledLink>
                </ElementTooltip>
              </td>
            </tr>
          </tbody>
        </table>
      </TableContainer>
      <footer className="d-flex justify-content-between">
        <div>
          <button
            disabled={exportInProgress}
            className="btn btn-maroon"
            type="button"
            onClick={onExportList}
          >
            <i className="fas fa-file-export" />
            &nbsp; Export List
          </button>

          {!isHidden && (
            <button
              disabled={exportInProgress}
              className="btn btn-maroon  ml-3"
              type="button"
              onClick={onExportAuditFile}
            >
              <i className="fas fa-file-export" />
              &nbsp; Export Audit File
            </button>
          )}
        </div>
        <div>
          <ElementTooltip
            tooltipId="publish-campaign-tooltip"
            tooltip={
              'This allows the Campaign to be available for use integrating with apps, ' +
              'sharing with other users or groups, etc. Once a Campaign is Published, ' +
              'you can no longer make edits to it.'
            }
          >
            <button
              className="btn btn-gold"
              type="button"
              disabled={campaign.published}
              aria-describedby="publish-campaign-tooltip"
              onClick={onPublishCampaign}
            >
              Publish Campaign
            </button>
          </ElementTooltip>
        </div>
      </footer>
    </div>
  );
}

export { DetailList };
