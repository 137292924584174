// @ts-check

// core
import {
  mapToCampaignClientModel,
  mapToCampaignWebApiModel,
} from 'core/utils/model-mapper-utils';
import { fetchData, postData, putData } from '../utils/http-utils';

/**
 * @param {CampaignModel} param
 */
const createCampaign = async (param) => {
  const campaignData = mapToCampaignWebApiModel(param);
  /** @type {AxiosResponse<CampaignPostPutResponse>} */
  const { data } = await postData('/campaign', campaignData.metadata);

  return data.metadata;
};

/**
 * @param {string} campaignId
 */
async function getCampaign(campaignId) {
  /** @type {AxiosResponse<CampaignResponse>} */
  const { data } = await fetchData(`/campaign/${campaignId}`);

  return mapToCampaignClientModel(data);
}

/**
 * @param {SearchFilter} filters
 */
async function getCampaigns({
  all,
  createdBy,
  createdFrom,
  createdTo,
  isLastPage,
  listShared,
  logicShared,
  nameQuery,
  outreachChannels,
  published,
  purposes,
  page,
  perPage,
}) {
  const searchParams = new URLSearchParams();

  // If Admin user, all=true should be passed to the API.
  // Otherwise, all=false or all param can be omitted.
  if (all) {
    searchParams.set('all', String(all));
  } else {
    searchParams.set('all', 'false');
  }

  if (createdBy) {
    searchParams.set('created_by', String(createdBy) + '@asu.edu');
  }

  if (createdFrom) {
    // API wants the date in this format: 'YYYY-MM-DD 00:00:00'.
    const date = new Date(createdFrom);
    const formattedDate = date.toISOString().slice(0, 10) + ' 00:00:00';
    searchParams.set('created_from', String(formattedDate));
  }

  if (createdTo) {
    const date = new Date(createdTo);
    const formattedDate = date.toISOString().split('T')[0];
    // Set the time to 23:59:59 so the entire day is included.
    const finalDate = `${formattedDate} 23:59:59`;

    searchParams.set('created_to', String(finalDate));
  }

  if (isLastPage) {
    searchParams.set('is_last_page', String(isLastPage));
  }

  if (listShared) {
    searchParams.set('list_shared', String(listShared));
  }

  if (logicShared) {
    searchParams.set('logic_shared', String(logicShared));
  }

  if (nameQuery) {
    searchParams.set('name_query', String(nameQuery));
  }

  if (outreachChannels && outreachChannels.length > 0) {
    outreachChannels.forEach((channel) => {
      // Use append because the user may select multiple Outreach Channels.
      searchParams.append('outreach_channel', String(channel));
    });
  }

  if (purposes && purposes.length > 0) {
    purposes.forEach((purpose) => {
      // Use append because the user may select multiple campaign Purposes.
      searchParams.append('purpose', String(purpose));
    });
  }

  if (published) {
    searchParams.set('published', String(published));
  }

  if (page) {
    searchParams.set('page', String(page));
  }

  if (perPage) {
    searchParams.set('per_page', String(perPage));
  }

  /** @type {AxiosResponse<CampaignSearchResponse>} */
  const { data } = await fetchData(`/campaign`, searchParams);

  return {
    campaigns: data.campaigns,
    isLastPage: data.is_last_page,
  };
}

/**
 * @param {string} campaignId
 * @param {CampaignModel} campaign
 */
async function updateCampaign(campaignId, campaign) {
  const campaignData = mapToCampaignWebApiModel(campaign);
  /** @type {AxiosResponse<CampaignPostPutResponse>} */
  const { data } = await putData(`/campaign/${campaignId}`, {
    action: 'update_metadata',
    metadata: campaignData.metadata,
  });

  return mapToCampaignClientModel(data);
}

export { getCampaigns, createCampaign, getCampaign, updateCampaign };
